const ContactField = (props) => {
  const capitalizeString = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className={(props.inputIsInvalid ? "form-invalid " : "") + props.name}>
      <label>{capitalizeString(props.name)}:</label>
      <div className="input-container">
        {!props.inputIsTextarea && (
          <input
            id={props.name}
            type="text"
            name={props.name}
            placeholder={"Your " + props.name}
            required=""
            onChange={props.onChange}
            onBlur={props.onBlur}
            className={
              props.inputIsInvalid ? "form-control is-invalid" : "form-control"
            }
          />
        )}
        {props.inputIsTextarea && (
          <textarea
            id={props.name}
            type="text"
            name={props.name}
            placeholder={"Your " + props.name}
            required=""
            onChange={props.onChange}
            onBlur={props.onBlur}
            className={
              props.inputIsInvalid ? "form-control is-invalid" : "form-control"
            }
          ></textarea>
        )}

        {props.inputIsInvalid && (
          <p className="error-text text-danger">
            {props.inputIsInvalidMessage}
          </p>
        )}
      </div>
    </div>
  );
};

export default ContactField;
