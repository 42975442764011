import { useState } from "react";
import Card from "../../UI/Card/Card";
import CardPopup from "../../UI/Card/CardPopup/CardPopup";
import "./Portfolio.css";

const Portfolio = (props) => {
  const [currentDataIndex, setCurrentDataIndex] = useState(0);
  const [modalState, setModalState] = useState(false);

  const dataIndexVal = (index) => {
    setCurrentDataIndex(index);
  };

  const isModalOpen = () => {
    setModalState(true);
  };

  const isModalClosed = () => {
    setModalState(false);
  };

  return (
    <div>
      <div id="portfolio">
        <div className="row h_portfolio flex_centered padding-default">
          <h3>Portfolio</h3>
        </div>
        <CardPopup
          handleModalClose={isModalClosed}
          isOpen={modalState}
          title={props.data[currentDataIndex].title}
          description={props.data[currentDataIndex].description}
          image={props.data[currentDataIndex].imgURL}
          preview={props.data[currentDataIndex].preview}
          video={props.data[currentDataIndex].video}
        />
        <div className="row">
          {props.data.map((item, index) => (
            <Card
              handleModalOpen={isModalOpen}
              dataIndex={dataIndexVal}
              index={index}
              key={index}
              bgColor={item.bgColor}
              title={item.title}
              imgURL={item.imgURL}
              isHighlighted={item.isHighlighted}
              url={item.url}
              githubURL_FE={item.githubURL_FE}
              githubURL_BE={item.githubURL_BE}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
