import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import YoutubeEmbed from "../YoutubeEmbed/YoutubeEmbed";

import "./ControlledCarousel.css";

const ControlledCarousel = (props) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      interval={3000}
      activeIndex={index}
      onSelect={handleSelect}
      controls={
        props.preview.image
          ? props.preview.image.length > 1
            ? true
            : false
          : false
      }
    >
      {props.preview.image &&
        props.preview.image.map((item, index) => (
          <Carousel.Item key={index}>
            <img className="d-block w-100" src={item} alt={index + " slide"} />
          </Carousel.Item>
        ))}
      {props.preview.video && <YoutubeEmbed embedId={props.preview.video} />}
    </Carousel>
  );
};

export default ControlledCarousel;
