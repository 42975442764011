import { useState } from "react";
import "./Contact.css";

// import { emailAccess } from "../../../services/email/email";
import emailjs from "emailjs-com";

import { BsCheck } from "react-icons/bs";
import ContactField from "./ContactField";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  //Name
  const [enteredName, setEnteredName] = useState("");
  const [enteredNameTouched, setEnteredNameTouched] = useState(false);
  const enteredNameIsValid = enteredName.trim() !== "";
  const nameInputIsInValid = !enteredNameIsValid && enteredNameTouched;

  const nameInputChangeHandler = (e) => {
    setEnteredName(e.target.value);
  };

  const nameInputBlurHandler = () => {
    setEnteredNameTouched(true);
  };

  //Email
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredEmailTouched, setEnteredEmailTouched] = useState(false);
  const enteredEmailIsValid = enteredEmail.trim() !== "";

  const emailInputChangeHandler = (e) => {
    setEnteredEmail(e.target.value);
  };

  const emailInputBlurHandler = () => {
    setEnteredEmailTouched(true);
  };

  const enteredEmailHasEmailFormat = () => {
    const allowedChars =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return allowedChars.test(String(enteredEmail).toLowerCase());
  };

  const emailErrorFeedback = () => {
    let feedbackMessage;
    let isInValid;

    if (!enteredEmailHasEmailFormat() && enteredEmailTouched) {
      feedbackMessage = "Please enter an email address";
      isInValid = true;

      if (!enteredEmailIsValid && enteredEmailTouched) {
        feedbackMessage = "Email must not be empty";
        isInValid = true;
      }
    } else {
      isInValid = false;
    }

    return [isInValid, feedbackMessage];
  };

  //Message
  const [enteredMessage, setEnteredMessage] = useState("");
  const [enteredMessageTouched, setEnteredMessageTouched] = useState(false);
  const enteredMessageIsValid = enteredMessage.trim() !== "";
  const messageInputIsInValid = !enteredMessageIsValid && enteredMessageTouched;

  const messageInputChangeHandler = (e) => {
    setEnteredMessage(e.target.value);
  };

  const messageInputBlurHandler = () => {
    setEnteredMessageTouched(true);
  };

  //Form valid
  let formIsValid = false;

  if (enteredNameIsValid && enteredEmailIsValid && enteredMessageIsValid) {
    formIsValid = true;
  }

  //Loading
  const isLoading = (loading) => {
    setLoading(loading);
  };

  //Success
  const handleSuccess = (val, message) => {
    setSuccess(val);
    setSuccessMessage(message);
  };

  const formSubmit = (e) => {
    e.preventDefault();

    if (!formIsValid) {
      return;
    }

    isLoading(true);

    

   emailjs
      .sendForm(
        "service_lthkhha",
        "template_cpeyzro",
        e.target,
        "_sGI0li5M3JVBVG5D" 
      )
      .then(
        (result) => {
          handleSuccess(true, "Message Sent, status: " + result.text);
          isLoading(false);
          e.target.reset();
        },
        (error) => {
          alert("An error occurred, Please try again", error.text);
        }
      ); 
  };
  return (
    <div id="contact">
      <div className="flex_centered">
        <div className="row contact-title padding-default">
          <h3>Get in touch</h3>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6">
          <form onSubmit={formSubmit}>
            <ContactField
              name={"name"}
              onChange={nameInputChangeHandler}
              onBlur={nameInputBlurHandler}
              inputIsInvalid={nameInputIsInValid}
              inputIsInvalidMessage={"Name must not be empty"}
            />

            <ContactField
              name={"email"}
              onChange={emailInputChangeHandler}
              onBlur={emailInputBlurHandler}
              inputIsInvalid={emailErrorFeedback()[0]}
              inputIsInvalidMessage={emailErrorFeedback()[1]}
            />

            <ContactField
              name={"message"}
              onChange={messageInputChangeHandler}
              onBlur={messageInputBlurHandler}
              inputIsInvalid={messageInputIsInValid}
              inputIsInvalidMessage={"Message must not be empty"}
              inputIsTextarea={true}
            />

            {success && (
              <div className="feedback">
                <BsCheck />
                <span> {successMessage}</span>
              </div>
            )}

            <div className={loading ? "loading submitForm" : "submitForm"}>
              <input
                disabled={!formIsValid}
                type="submit"
                value="Submit Form"
              />
              {loading && (
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
