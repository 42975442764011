import "./Intro.css";

const Intro = (props) => {
  return (
    <div>
      <div className="about flex_centered">
        <div className="intro">
          <h3>Who am I?</h3>

          <p>
            My name is Levente Bognar, aiming to find a full-time job where I
            can grow as a web-developer and enrich my skillset in areas of
             Angular, React, NodeJs (I'm JS framework focused). In my free time - besides developing
            websites - I love to play the guitar and make videos, grow plants and travel a lot.
          </p>

          <p>
            I currently reside in Hungary and I'm willing to move if need be for
            work.
          </p>

          <span></span>
        </div>
      </div>
    </div>
  );
};

export default Intro;
