import "./Main.css";

const Main = (props) => {
  return (
    <div>
      <div className="slider">
        <div className="col-sm-6 cover-image float-start">
          <div
            className="bg"
            style={{ backgroundImage: `url(${props.bgImageURL})` }}
          >
            <div className="col-sm-6 col float-right full-height flex_centered">
              <div className="text">
                <div className="white-text">
                  <div className="letter-i">
                    <span className="huge-word">I</span>
                  </div>

                  <div className="letter-ap">
                    <span className="huge-ap">’</span>
                  </div>

                  <div className="letter-name-and-m">
                    <span className="m-word">M</span>

                    <span className="name-word">Levente</span>
                  </div>

                  <span className="mid-word">a</span>
                </div>

                <span className="small-word">
                  <span className="a_char">a</span> web developer
                </span>

                <span className="subtitle">
                  looking for challenges and{" "}
                  <span className="orange"> work</span>
                </span>

                <a href="#">
                  <button>See more</button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-6 tile-images float-start">
          <a href="http://leventebognar.com/cv.pdf">
            <div className="col-sm-6 margin padding-top-left float-start">
              <div className="tile-box icon_cv_pic flex_centered">
                <div className="img_and_text">
                  <img className="cv_image" src={props.cvGraficsURL} />

                  <h4 className="">CV</h4>
                </div>
              </div>
            </div>
          </a>

          <div className="col-sm-6 margin padding-top-right">
            <div
              className="tile-box icon_cv"
              style={{ backgroundImage: `url(${props.cvImageURL})` }}
            ></div>
          </div>

          <div className="col-sm-6 margin padding-bottom-left float-start">
            <div
              className="tile-box icon_pf"
              style={{ backgroundImage: `url(${props.pfImageURL})` }}
            ></div>
          </div>

          <a href="#portfolio">
            <div className="col-sm-6 margin padding-bottom-right float-start">
              <div className="tile-box icon_pf_pic flex_centered">
                <div className="img_and_text">
                  <img className="pf_image" src={props.pfGraphicsURL} />

                  <h4 className="">Portfolio</h4>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Main;
