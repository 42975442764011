import showCaseIMG from "../img/showCase_thmb.png";
import webDev_thmb_preview from "../img/preview/webDev_thmb_preview.png";
import webDev_thmb_preview2 from "../img/preview/webDev_thmb_preview4.png";

import getItCheaperIMG from "../img/getitcheaper_andr.png";
import getitcheaper_preview from "../img/preview/getitcheaper_preview.png";

import webDevIMG from "../img/mac_thmb.png";
import mac_thmb_preview from "../img/preview/mac_thmb_preview.png";
import mac_thmb_preview2 from "../img/preview/mac_thmb_preview2.png";

import mobDevIMG from "../img/mac_andr.png";
import mac_andr_preview from "../img/preview/mac_andr_preview.png";

import angularIMG from "../img/ang_thmb.png";
import ang_thmb_preview from "../img/preview/ang_thmb_preview.png";

import flierIMG from "../img/flier_thmn.png";
import flier_thmn_preview from "../img/preview/flier_thmn_preview.png";
import flier_thmn_preview2 from "../img/preview/flier_thmn_preview2.png";

import mb_IMG from "../img/mb_thmb.png";
import mb_thmb_preview from "../img/preview/mb_thmb_preview.png";
import mb_thmb_preview2 from "../img/preview/mb_thmb_preview2.png";

import vid1IMG from "../img/vi1_thmb.png";
import vid2IMG from "../img/vi2_thmb.png";

export const Data = [
  {
    bgColor: "purple",
    title: "Showcase app (Angular)",
    description:
      "The purpose of this project is to demonstrate my Angular/NodeJS skills.\n \n  Brief summary:\n  -User Authentication \n  -Form validation \n -Login \n -Signup \n -Creating,Reading,Updating,Deleting Articles \n -Deleting Users (check to not delete yourself) \n  \n Used technologies: Angular, NodeJS, MongoDB",
    imgURL: showCaseIMG,
    preview: {
      image: [webDev_thmb_preview, webDev_thmb_preview2],
    },
    isHighlighted: true,
    url: "http://leventebognar.com/showcase_app/",
    githubURL_FE: "https://github.com/lajos93/ui_challenge-frontend",
    githubURL_BE: "https://github.com/lajos93/showcase_backend",
  },
  {
    bgColor: "blue",
    title: "Angular (Ionic)",
    description:
      "The purpose of this project came from my student years where I aimed to cut down on my monthly food costs.\n \n  What I implemented here is an Ionic based mobile app that's main function is to send notifications in a set number of days about our favourite food items.\n \n The way it works is I gather all the important weekly discounts from a source, save it to a database and send Push Notifications to the devices.\n \n  Used technologies: Ionic(Angular), NodeJS, MongoDB",
    imgURL: getItCheaperIMG,
    preview: {
      image: [getitcheaper_preview],
    },
  },
  {
    bgColor: "orange",
    title: "Web development",
    description:
      "My name is Lorem Ipsum, aiming to find an internship where I can grow as a web-developer and enrich my skillset working mainly with Wordpress and Angular 2 based websites aiming to find an internship where I can grow as a web-developer and enrich my skillset working mainly with Wordpress and Angular 2 based websites.",
    imgURL: webDevIMG,
    preview: {
      image: [mac_thmb_preview, mac_thmb_preview2],
    },
  },
  {
    bgColor: "orange",
    title: "Mobile development",
    description:
      "My name is Lorem Ipsum, aiming to find an internship where I can grow as a web-developer and enrich my skillset working mainly with Wordpress and Angular 2 based websites aiming to find an internship where I can grow as a web-developer and enrich my skillset working mainly with Wordpress and Angular 2 based websites.",
    imgURL: mobDevIMG,
    preview: {
      image: [mac_andr_preview],
    },
  },
  {
    bgColor: "grey",
    title: "Angular",
    description:
      "My name is Lorem Ipsum, aiming to find an internship where I can grow as a web-developer and enrich my skillset working mainly with Wordpress and Angular 2 based websites aiming to find an internship where I can grow as a web-developer and enrich my skillset working mainly with Wordpress and Angular 2 based websites.",

    imgURL: angularIMG,
    preview: {
      image: [ang_thmb_preview],
    },
  },
  {
    bgColor: "yellow",
    title: "Flyier design",
    description:
      "My name is Lorem Ipsum, aiming to find an internship where I can grow as a web-developer and enrich my skillset working mainly with Wordpress and Angular 2 based websites aiming to find an internship where I can grow as a web-developer and enrich my skillset working mainly with Wordpress and Angular 2 based websites.",

    imgURL: flierIMG,
    preview: {
      image: [flier_thmn_preview, flier_thmn_preview2],
    },
  },
  {
    bgColor: "blue",
    title: "Semester project",
    description:
      "My name is Lorem Ipsum, aiming to find an internship where I can grow as a web-developer and enrich my skillset working mainly with Wordpress and Angular 2 based websites aiming to find an internship where I can grow as a web-developer and enrich my skillset working mainly with Wordpress and Angular 2 based websites.",

    imgURL: mb_IMG,
    preview: {
      image: [mb_thmb_preview, mb_thmb_preview2],
    },
  },
  {
    bgColor: "blue",
    title: "Video",
    description:
      "My name is Lorem Ipsum, aiming to find an internship where I can grow as a web-developer and enrich my skillset working mainly with Wordpress and Angular 2 based websites aiming to find an internship where I can grow as a web-developer and enrich my skillset working mainly with Wordpress and Angular 2 based websites.",

    imgURL: vid1IMG,
    preview: {
      video: "7L0JKFgA-og",
    },
  },
  {
    bgColor: "yellow",
    title: "Award winner video",
    description:
      "My name is Lorem Ipsum, aiming to find an internship where I can grow as a web-developer and enrich my skillset working mainly with Wordpress and Angular 2 based websites aiming to find an internship where I can grow as a web-developer and enrich my skillset working mainly with Wordpress and Angular 2 based websites.",

    imgURL: vid2IMG,
    preview: {
      video: "jj426-wWi3c",
    },
  },
];
