import { useState } from "react";

const Card = (props) => {
  const openModal = () => {
    props.handleModalOpen();
    props.dataIndex(props.index);
  };

  const [hovered, setHovered] = useState(false);
  const [highlightedClickable, sethighlightedClickable] = useState(false);

  const toggleHoverON = () => {
    setHovered(true);
  };

  const toggleHoverOFF = () => {
    setHovered(false);
  };

  const openLink = () => {
    window.open(props.url);
  };

  const canClick = () => {
    if (props.isHighlighted) {
      return true;
    }
    return false;
  };

  const highlightedClickableHandlerOn = () => {
    sethighlightedClickable(true);
  };

  const highlightedClickableHandlerOff = () => {
    sethighlightedClickable(false);
  };

  const defineClass = () => {
    return (
      (props.isHighlighted ? "col-sm-6 highlighted" : "col-sm-3") +
      " thumbnail_big  flex_centered " +
      "bg_" +
      props.bgColor
    );
  };

  return (
    <div className={defineClass()}>
      <div
        onClick={
          canClick() ? (highlightedClickable ? undefined : openLink) : openModal
        }
        className={hovered ? "width-img-highlight" : ""}
        onMouseEnter={toggleHoverON}
        onMouseLeave={toggleHoverOFF}
      >
        <div className="thumbnail-inner-container">
          {" "}
          <span
            onClick={canClick() ? openModal : undefined}
            onMouseOver={highlightedClickableHandlerOn}
            onMouseLeave={highlightedClickableHandlerOff}
            className={hovered ? " opacity-span" : ""}
          >
            {props.title}
          </span>
          {props.url && (
            <a
              onMouseOver={highlightedClickableHandlerOn}
              onMouseLeave={highlightedClickableHandlerOff}
              href={props.url}
              target="_blank"
              className={(hovered ? "opacity-span" : "") + " link"}
            >
              (Link to the site)
            </a>
          )}
          {props.githubURL_FE && (
            <a
              onMouseOver={highlightedClickableHandlerOn}
              onMouseLeave={highlightedClickableHandlerOff}
              href={props.githubURL_FE}
              target="_blank"
              className={(hovered ? "opacity-span" : "") + " link"}
            >
              (Frontend code)
            </a>
          )}
          {props.githubURL_BE && (
            <a
              onMouseOver={highlightedClickableHandlerOn}
              onMouseLeave={highlightedClickableHandlerOff}
              href={props.githubURL_BE}
              target="_blank"
              className={(hovered ? "opacity-span" : "") + " link"}
            >
              (Backend code)
            </a>
          )}
        </div>
      </div>
      <img
        className={hovered ? " width-img-highlight" : ""}
        src={props.imgURL}
      />
    </div>
  );
};

export default Card;
