import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./components/UI/Header/Header";
import Main from "./components/Sections/Main/Main";
import Intro from "./components/Sections/Intro/Intro";
import Portfolio from "./components/Sections/Portfolio/Portfolio";
import Skills from "./components/Sections/Skills/Skills";
import Contact from "./components/Sections/Contact/Contact";

//Image imports
import logo from "./img/logo.svg";
import bg2 from "./img/bg2.png";
import pf_graphics from "./img/portfolio_graphics.svg";
import cv_graphics from "./img/cv_graphics.svg";

import cv_image from "./img/im1.png";
import pf_image from "./img/im2.png";

//Data
import { Data } from "./data/data";
import { SkillsData } from "./data/skills";

function App() {
  return (
    <div className="App">
      <Header imageURL={logo} />
      <Main
        bgImageURL={bg2}
        cvGraficsURL={cv_graphics}
        cvImageURL={cv_image}
        pfGraphicsURL={pf_graphics}
        pfImageURL={pf_image}
      />
      <Intro />
      <Portfolio data={Data} />
      <Skills data={SkillsData} />
      <Contact />
    </div>
  );
}

export default App;
