const Label = (props) => {
  return (
    <div className={"skill-container " + props.color}>
      <span className={"skill-text " + props.color}>
        <span className={"skill-title " + props.color}>{props.title}</span>
        {props.description}
      </span>
    </div>
  );
};
export default Label;
