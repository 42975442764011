
import React from 'react'
import { Modal } from "react-bootstrap";
import ControlledCarousel from "../ControlledCarousel/ControlledCarousel";

import "./CardPopup.css";

const CardPopup = (props) => {
  const handleClose = () => {
    props.handleModalClose();
  };

  return (
    <>
      <Modal show={props.isOpen} onHide={handleClose}>
        <div className="left-modal-side">
          <ControlledCarousel preview={props.preview} />
        </div>
        <div className="right-modal-side">
          <h2 className="mt-3">{props.title}</h2>
          <p className="description">{props.description}</p>
        </div>
      </Modal>
    </>
  );
};

export default CardPopup;
