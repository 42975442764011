import "./Header.css";

const Header = (props) => {
  return (
    <div>
      <nav className="menubar padding-default">
        <ul className="logo-element">
          <li className="logo">
            <img src={props.imageURL} />
          </li>
        </ul>
        <ul className="menu-elements">
          <li>
            <a href="#">Home</a>
          </li>
          <li>
            <a href="#portfolio">Portfolio</a>
          </li>
          <li>
            <a href="#skills">Skills</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
