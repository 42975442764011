export const SkillsData = [
  {
    color: "orange",
    title: "html5",
    description: "To build structure",
  },
  {
    color: "cian",
    title: "css/scss",
    description: "To make it visually appealing",
  },
  {
    color: "yellow",
    title: "js",
    description: "To provide functionality",
  },
  {
    color: "grey",
    title: "jquery",
    description: "An easy, and simple javascript framework",
  },
  {
    color: "angular",
    title: "Angular",
    description: "A well documented framework to simply to implement solutions",
  },

  {
    color: "react",
    title: "React",
    description: "A widely used, highly scalable JS framework",
  },

  {
    color: "rest",
    title: "REST",
    description: "To make the communication between the backend/frontend easy",
  },
  {
    color: "json",
    title: "json",
    description: "A format to store data",
  },
  {
    color: "php",
    title: "php",
    description: "A well built language to build the backend of a system",
  },
  {
    color: "wp",
    title: "wordpress",
    description: "A popular web framework",
  },
];
