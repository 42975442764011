import Label from "../../UI/Label/Label";
import "./Skills.css";
import computerIMG from "../../../img/computer.png";

const Skills = (props) => {
  return (
    <div id="skills">
      <div className="flex_centered">
        <div className="row desc">
          <h3>Skills</h3>

          <p>
            Throughout my experiences during education and work I have gained
            skills in areas of Html,CSS,JS, Jquery, Wordpress and Angular
          </p>
        </div>
      </div>

      <div className="row skills-pic-and-desc ">
        <div className="col-sm-6 pic_computer">
          <div className="pic-computer_border_bottom">
            <img src={computerIMG} />
          </div>
        </div>

        <div className="col-sm-6 skills-title">
          <div className="skills-align">
            {props.data.map((item, index) => (
              <Label
                key={index}
                color={item.color}
                title={item.title}
                description={item.description}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
